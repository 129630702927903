<template>
    <div>
        <div class="banner-wrapper">
<!--            <img width="100%" height="600px" :src="bannerPath" style="object-fit: cover"/>-->
          <slot name="content"></slot>
        </div>
        <div>
<!--            <swiper :options="swiperOptions" ref="mySwiper">-->
<!--                <swiper-slide v-for="img in imageList" :key="img.index">-->
<!--                    <img :src="img.image" width="232px" height="200px">-->
<!--                </swiper-slide>-->
<!--            </swiper>-->
        </div>

<!--            <vue-seamless-scroll :data="imageList" class="seamless-warp" :class-option="classOptionLeft">-->

<!--                    <div v-for="img in imageList" :key="img.index">-->
<!--                                            <img :src="img.image" width="50px" height="50px">-->
<!--                                        </div>-->

<!--            </vue-seamless-scroll>-->
<!--            <el-carousel :indicator="false" type="card" :autoplay="false" height="500px">-->
<!--                <el-carousel-item v-for="img in imageList" :key="img.index">-->
<!--                    <img :src="img.image" width="500px" height="500px">-->
<!--                </el-carousel-item>-->
<!--            </el-carousel>-->
    </div>
</template>

<script>
    // import vueSeamlessScroll from 'vue-seamless-scroll'
    // import { swiper, swiperSlide } from 'vue-awesome-swiper'
    // import 'swiper/dist/css/swiper.css'
    export default {
        name: 'prefecture',
        components: {
            // vueSeamlessScroll
            // swiper,
            // swiperSlide
        },
        props: {
            bannerPath: {
                required: true,
                type: String,
                default: ''
            },
            imageList: {
                required: true,
                type: Array,
                default: null
            }
        },
        computed: {
            classOptionLeft() {
                return {
                    step: 0.5, // 数值越大速度滚动越快
                    limitMoveNum: 5, // 开始无缝滚动的数据量 this.dataList.length
                    hoverStop: true, // 是否开启鼠标悬停stop
                    direction: 2, // 0向下 1向上 2向左 3向
                    singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
                    singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
                    waitTime: 1000 // 单步运动停止的时间(默认值1000ms)
                }
            },
            // classOptionRight() {
            //     return {
            //         // step: 0.5, // 数值越大速度滚动越快
            //         limitMoveNum: this.imageList.length, // 开始无缝滚动的数据量 this.dataList.length
            //         hoverStop: true, // 是否开启鼠标悬停stop
            //         direction: 2, // 0向下 1向上 2向左 3向
            //         singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
            //         singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
            //         waitTime: 1000 // 单步运动停止的时间(默认值1000ms)
            //     }
            // }
            swiper() {
                return this.$refs.mySwiper.swiper
            }

        },
        data () {
            return {
                swiperOptions: {
                    loop: true,
                    notNextTick: true,
                    speed: 3000,
                    freeMode: true,
                    autoplay: {
                        delay: 3000,
                        stopOnLastSlide: false,
                        disableOnInteraction: false
                    },
                    pagination: {
                        el: '.swiper-pagination',    //与slot="pagination"处 class 一致
                        clickable: true,            //轮播按钮支持点击
                    },
                    slidesPerView: 6,
                    spaceBetween: 0
                }
            }
        },
        created () {
        }
    }
</script>

<style scoped>
.region-header {
  text-align: center;
  padding: 12% 60% 10% 13%;
}
  .banner-wrapper {
    width: 100%;
    height: 500px;
    background-image: url("../../../../assets/img/prefecture/topBanner3.png");
    background-size: cover;
  }
  .title {
    font-size: 80px;
    font-weight: 700;
    color: #FFFFFF;
  }
  .title-red {
    padding:0px 10px;
    font-size: 80px;
    font-weight: 700;
    color: #FFFFFF;
    background-color: rgba(227, 60, 100, 1);
  }
  .sub-header {
    padding: 0px 10px;
    text-align: center;
    width: 100%;
    margin-top: 20px;
    font-size: 20px;
    color: #FFFFFF;
  }
  .sub-header span {
    margin-left: 5px;
    margin-right: 5px;
  }
  .sub-header i {
    margin-right: 5px;
  }
</style>
