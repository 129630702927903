<template>
    <div style="background-color: white">
        <div class="tac mt-4">
            <el-button-group>
                <el-button size="small" @click="getList(item.itemCode, index)"
                           :class="itemCode === item.itemCode ? 'pr on' : 'pr'" v-for="(item, index) in cfItemList" :key="index">
                    {{item.itemName}}</el-button>
            </el-button-group>
        </div>

<!--        <el-divider></el-divider>-->
        <div style="width: 1100px;margin: 0 auto;margin-top: 20px">
            <el-row :gutter="20">
                <el-col v-for="item in serviceList" :key="item.id" :span="6">
<!--                    <el-card style="height: 316px;width: 405px;margin: 0 auto;margin-bottom: 20px;">-->
<!--                        <i :class="iconList[index]" style="font-size: 40px;margin-top: 10px"></i>-->
<!--                        <div style="font-size: 30px;font-weight: bold;margin-top: 15px" :title="item.serviceName">-->
<!--                            {{ellipsis(item.serviceName, 11)}}-->
<!--                        </div>-->
<!--                        <div style="height: 120px;margin-top: 15px;color: #909399" :title="item.itemDesc">-->
<!--                            {{ellipsis(item.itemDesc, 120)}}-->
<!--                        </div>-->
<!--                        <div style="text-align: right;">-->
<!--                            <el-button  @click="goDetail(item.id)" style="background-color:  #2405F2;color: white;">立即使用</el-button>-->
<!--                        </div>-->
<!--                    </el-card>-->
                    <service-card :service="item" @showDetail="chooseProduct(item)" :key="item.id"/>
                </el-col>
            </el-row>

            <div class="pb-4" style="text-align: right">
                <el-pagination
                        background
                        @size-change="sizeChangeHandle"
                        @current-change="currentChangeHandle"
                        :current-page="pageIndex"
                        :page-sizes="[8]"
                        :page-size="pageSize"
                        :total="totalCount"
                        layout="prev, pager, next">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
    import ServiceCard from '../../../components/service-card'
    export default {
        name: 'service',
        components: {
            ServiceCard
        },
        props: {
            cfItemList: {
                required: true,
                type: Array,
                default: null
            },
            pageIndex: {
                required: true,
                type: Number,
                default: 1
            },
            pageSize: {
                required: true,
                type: Number,
                default: 8
            },
            itemCode: {
                required: true,
                type: String,
                default: ''
            },
            totalCount: {
                required: true,
                type: Number,
                default: 0
            },
            serviceList: {
                required: true,
                type: Array,
                default: null
            }
        },
        computed: {
            bstyle() {
                let colorList = ['red', 'bule', 'green', 'grey']
                return {
                    '--background': colorList[index]
                }
            }
        },
        data () {
            return {
                itemName: '',
                index: 0,
                iconList: [
                    'icon-jiance iconfont',
                    'icon-zu iconfont',
                    'icon-zhiliang iconfont',
                    'icon-anquan iconfont',
                    'icon-chengxinxi iconfont',
                    'icon-medal iconfont',
                    'icon-docs iconfont',
                    'icon-zhishichanquan iconfont',
            'icon-jiance iconfont',
                'icon-zu iconfont',
                'icon-zhiliang iconfont',
                'icon-anquan iconfont',
                'icon-chengxinxi iconfont',
                    'icon-medal iconfont',
                    'icon-docs iconfont',
                    'icon-zhishichanquan iconfont',
                ],
                pIndex: 1,
                pSize: 8
            }
        },
        created () {

        },
        methods: {
            getServiceList () {
                this.$emit('getDataList', this.itemCode,  this.pIndex, this.pSize)

            },
            getList(itemNode, index) {
                this.pIndex = 1
                this.itemCode = itemNode
                this.index = index
                this.getServiceList()
            },
            // 选择产品
            chooseProduct (item) {
                let itemCodeType = 'search'
                this.$router.push({name: 'service', query: {id: item.id,itemCodeType:itemCodeType}})
            },
            // 每页数
            sizeChangeHandle(val) {
                this.getServiceList()
            },
            // 当前页
            currentChangeHandle(val) {
                this.pIndex = val
                this.getServiceList()
            },
            goDetail(id) {
                this.$router.push({name: 'service', query: {id, isProvider: false}})
            },
            //控制字数
            ellipsis (value, num) {
                if (!value) return ''
                if (value.length > num) {
                    return value.slice(0,num) + '...'
                }
                return value
            }
        }
    }
</script>

<style scoped>
    .title {
      padding-top: 30px;
        font-size: 50px;
        font-weight: bold;
        text-align: center;
        /*margin-top: 30px;*/
        /*margin-bottom: 30px;*/
    }
    .service-card {
        height: 200px;
    }

    .service-card i {
        font-size: 80px;
        padding: 20px;
    }
    .service-card span {
        font-size: 80px;
        padding: 20px;
    }
    .service-card:hover {
        cursor: pointer;
        background-color: #F2F6FC;
    }
    .bcard{
        min-height: 240px;
    }
    .bcard:nth-child(odd) {
        /*background-color: #CCFFCC;*/
        background-color: #CCCCFF;
        opacity: 80%;
    }
    .bcard:nth-child(even) {
        background-color: #CCCCFF;
    }
    .bcard span{
        font-size: 60px;
        font-weight: bold;
        color: whitesmoke;
    }

    .el-card {
        border: none;
    }

    .ebutton /deep/ .el-button {
        background-color:  #4647BB;
        color: white;
    }
    .ebutton :hover{
        color: #FFFFFF;
        background-color: #05a4ff;
    }
    /*.ebutton :active{*/
    /*    background-color: #05a4ff;*/
    /*}*/
    /*.ebutton :focus{*/
    /*    background-color: #05a4ff;*/
    /*}*/
    .eb{
          background-color: #05a4ff;
      }
    .en{
    }
</style>
