<template>
  <div style="width: 1200px;margin: 0 auto;background-color: #F4F6FC">
    <div class="banner-wrapper">
    </div>
    <div class="tac mt-5 mb-3" style="background-color: white;margin-top: -1px">
      <img src="~@/assets/img/prefecture/cqcremen.png" height="64px" style="margin-top: 40px">
      <service :cfItemList="cfItemList" :service-list="serviceList" @getDataList=getDataList
               :page-index="pageIndex" :page-size="pageSize" :total-count="totalCount"></service>
      <el-divider></el-divider>
      <div class="tac mt-5 mb-3">
        <img src="~@/assets/img/prefecture/cqcsupport.png" height="64px">
      </div>
    </div>

    <div class="jianjie p-5" style="margin-top: -15px">
      <div style="text-indent: 40px;line-height: 180%;margin-bottom: 20px;">
                <span>
                  中国质量认证中心（CQC）始终以服务国家经济社会发展和提升人民生活品质为己任，依托产品认证(包括国家强制性产品认证、自愿性产品认证)、
                  管理体系认证和认证培训业务，着力开展节能、节水(“节”字标)和环保产品认证工作。在积极促进国际贸易，调整经济结构，保护消费者安全健康，
                  构建社会诚信体系，参与“两型”社会建设等方面做出了积极贡献。同时，自身获得了跨越式发展，已成为业务门类全、服务网络广、工作手段新、
                  技术力量强、人员素质高的一流认证机构，可以方便快捷地为世界各地的客户提供高效、优质的“一站式”服务。
                </span>
      </div>
      <div style="text-indent: 40px;line-height: 180%;margin-bottom: 20px">
                <span>
CQC秉承“和谐、进取、责任”的理念，正在朝着建立社会公信力高，有较强创新能力、市场竞争能力和可持续发展能力，
                  在业界有较高知名度的国际型认证机构的目标努力前行。
                </span>
      </div>
      <div style="text-indent: 40px;line-height: 180%;margin-bottom: 20px">
                <span>
                  CQC 在国内外共设有45 个分支机构。遍布全国的服务网络，能够为客户提供及时、周到、高质量的服务。
                </span>
    </div>
      <div style="text-indent: 40px;line-height: 180%;margin-bottom: 20px">
                <span>
                  CQC 是IECEE-CB 体系中唯一的国家认证机构（ NCB ）和IQNet的正式成员。CQC 与国外诸多知名认证机构间的国际互认业务，以及广泛的国际交流和良好的国际形象，都能使客户享受增值服务。
优质的服务、雄厚的技术力量、先进的管理水平保障了CQC 业务的顺利开展，为顺利实现 CQC 的质量目标、为CQC 这个国内外知名认证机构的品牌形象提供了有力保障。
                </span>
      </div>
      <div style="text-indent: 40px;line-height: 180%;margin-bottom: 20px">
                <span>
                  CQC 业务范围:
一、 授权承担国家强制性产品认证（ CCC ）工作。
二、 CQC 标志认证：认证类型涉及产品安全、性能、环保、有机产品等，认证范围包括百余种产品。
三、 管理体系认证：主要从事ISO9001质量管理体系、ISO14001环境管理体系、OHSMS18001职业健康安全管理体系、QS9000 质量体系、TL9000和HACCP 认证等业务。
四、 作为国际电工委员会电工产品合格与测试组织（IECEE）的中国国家认证机构（ NCB ），从事颁发和认可国际多边认可CB 测试证书工作，其证书被 43 个国家和地区的 59 个国家认证机构所认可。
五、 作为国际认证联盟（ IQNet ）的成员， CQC 颁发的IS09001 证书和ISO14001 证书将能获得联盟内其他 33 个国家和地区的 36 个成员机构的认可。
六、 认证培训业务：作为经中国认证人员与培训机构国家认可委员会（CNAS）认可的中国最早的认证培训机构，承担国内外各类认证培训业务。
                </span>
      </div>
      <div style="text-indent: 40px;line-height: 180%;margin-bottom: 20px">
                <span>
                  CQC的宗旨、原则与管理体系
“公正规范，诚信高效，优质科学，创新卓越”是CQC开展认证工作的指导方针。
客观、独立和公正是CQC认证工作的基本原则。CQC遵循非歧视性的方针和工作程序。针对运作和/或活动中所引发的责任和风险，建立了充足的认证风险基金以承担与CQC有关的认证责任。CQC是不以营利为目的的认证机构，不接受社会各方任何形式的经济赞助，财务实行独立核算，有稳定的财务状况和良好的财务监督机制。CQC及其各级人员和所有参与认证过程的委员会都不受可能影响认证结论的任何商业、财务和其它方面的压力。可以在广泛的认证领域和专业范围为相关方提供满意的服务。
CQC遵守国家认证认可相关法律法规和各相关方的要求及规范，内部建立运行完整的质量管理体系，通过保证公正性的组织结构，确保CQC以高度的诚信实施认证业务。
                </span>
      </div>
      <div style="text-indent: 40px;line-height: 180%;margin-bottom: 20px">
                <span>
质量目标： 以优质高效的工作作风及整体一致的工作水平，赢得客户的信任；在认证领域，始终保持国内领先地位；跻身于国际著名认证机构行列。
                </span>
      </div>
    </div>
    <div style="background-color: white;height: 120px;margin-top: -50px">
      <div class="tac mt-5 mb-3">
        <img src="~@/assets/img/prefecture/cqczizhi.png" height="64px" style="margin-top: 40px">
      </div>
    </div>

    <div class="zizhi p-5">
      <div style="font-size: 30px;font-weight: bold">
        国际资质
      </div>
      <div style="margin-top: 40px">
        <el-row style="margin-bottom: 40px">
          <el-col :span="12">
            <span class="dian">●</span>
            <span style="font-size: 20px;margin-left: 20px">国际电工委员会电工设备及零部件合格评定组织 - 测试证书互认体系（IECEE-CB）中国国家认证机构（NCB）</span>
          </el-col>
          <el-col :span="12">
            <span class="dian">●</span>
            <span style="font-size: 20px;margin-left: 20px">国际电工委员会可再生能源设备标准认证互认体系（IECRE）风能及光伏认证机构（RECB）</span>
          </el-col>
        </el-row>
        <el-row style="margin-bottom: 40px">
          <el-col :span="12">
            <span class="dian">●</span>
            <span style="font-size: 20px;margin-left: 20px">国际认证联盟（IQNet）中国成员机构。代表IQNet有限公司在中国大陆提供SA8000（社会责任标准认证）、IQNet SR 10（社会责任管理体系认证）、Sedex SMETA （Sedex会员道德贸易审核）等认证和审核业务</span>
          </el-col>
          <el-col :span="12">
            <span class="dian">●</span>
            <span style="font-size: 20px;margin-left: 20px">国际有机农业运动联盟（IFOAM）正式成员）</span>
          </el-col>
        </el-row>
        <el-row style="margin-bottom: 40px">
          <el-col :span="12">
            <span class="dian">●</span>
            <span style="font-size: 20px;margin-left: 20px">亚洲网络论坛（ANF）正式成员</span>
          </el-col>
          <el-col :span="12">
            <span class="dian">●</span>
            <span style="font-size: 20px;margin-left: 20px">全球环境标志网络GEN授权的正式成员</span>
          </el-col>
        </el-row>
        <el-row style="margin-bottom: 40px">
          <el-col :span="12">
            <span class="dian">●</span>
            <span style="font-size: 20px;margin-left: 20px">国际机动车检测委员会（CITA）中国唯一成员</span>
          </el-col>
          <el-col :span="12">
            <span class="dian">●</span>
            <span style="font-size: 20px;margin-left: 20px">全球良好农业规范（GLOBAL G.A.P）授权认证机构、正式会员</span>
          </el-col>
        </el-row>
        <el-row style="margin-bottom: 40px">
          <el-col :span="12">
            <span class="dian">●</span>
            <span style="font-size: 20px;margin-left: 20px">全球食品安全倡议组织GFSI中国工作组的会员单位</span>
          </el-col>
          <el-col :span="12">
            <span class="dian">●</span>
            <span style="font-size: 20px;margin-left: 20px">联合国气候变化框架公约组织下清洁发展机制执行理事会（CDM-EB）授权的指定经营实体（DOE）</span>
          </el-col>
        </el-row>
        <el-row style="margin-bottom: 40px">
          <el-col :span="12">
            <span class="dian">●</span>
            <span style="font-size: 20px;margin-left: 20px">联合国开发计划署（UNDP）、世界银行（WB）、美国能源基金会（EF）、美国环保协会（EDF）、国际铜业协会（ICA）在中国的合作伙伴及项目承担单位</span>
          </el-col>
          <el-col :span="12">
            <span class="dian">●</span>
            <span style="font-size: 20px;margin-left: 20px">国际环保组织（VERRA）批准的经核证的碳减排标准（VCS）、气候&社区及生物多样性标准（CCB）、可持续发展影响评价标准（SD VISta）的第三方核查机构（VVB）</span>
          </el-col>
        </el-row>
        <el-row style="margin-bottom: 40px">
          <el-col :span="12">
            <span class="dian">●</span>
            <span style="font-size: 20px;margin-left: 20px">欧盟碳排放权交易体系EU-ETS航空领域第三方核查机构</span>
          </el-col>
          <el-col :span="12">
            <span class="dian">●</span>
            <span style="font-size: 20px;margin-left: 20px">欧盟碳排放权交易体系EU-ETS航海运输领域第三方核查机构</span>
          </el-col>
        </el-row>
        <el-row style="margin-bottom: 40px">
          <el-col :span="12">
            <span class="dian">●</span>
            <span style="font-size: 20px;margin-left: 20px">国内首批国际航空碳抵消和减排计划机制（CORSIA）下的第三方核查机构</span>
          </el-col>
          <el-col :span="12">
            <span class="dian">●</span>
            <span style="font-size: 20px;margin-left: 20px">气候债券标准委员会（CBS）授权的第三方核查机构</span>
          </el-col>
        </el-row>
        <el-row style="margin-bottom: 40px">
          <el-col :span="12">
            <span class="dian">●</span>
            <span style="font-size: 20px;margin-left: 20px">食品安全体系（FSSC22000）认证授权的认证机构</span>
          </el-col>
          <el-col :span="12">
            <span class="dian">●</span>
            <span style="font-size: 20px;margin-left: 20px">BRCGS全球标准授权认证机构</span>
          </el-col>
        </el-row>
        <el-row style="margin-bottom: 40px">
          <el-col :span="12">
            <span class="dian">●</span>
            <span style="font-size: 20px;margin-left: 20px">雨林联盟授权认证机构</span>
          </el-col>
          <el-col :span="12">
            <span class="dian">●</span>
            <span style="font-size: 20px;margin-left: 20px">生物质国际可持续性和碳认证组织（ISCC）授权的国内首家认证机构</span>
          </el-col>
        </el-row>
        <el-row style="margin-bottom: 40px">
          <el-col :span="12">
            <span class="dian">●</span>
            <span style="font-size: 20px;margin-left: 20px">日本强制性产品认证（PSE）中国授权机构</span>
          </el-col>
          <el-col :span="12">
            <span class="dian">●</span>
            <span style="font-size: 20px;margin-left: 20px">海湾标准化组织（GSO）授权的GC标志认证指定机构</span>
          </el-col>
        </el-row>
        <el-row style="margin-bottom: 40px">
          <el-col :span="12">
            <span class="dian">●</span>
            <span style="font-size: 20px;margin-left: 20px">沙特阿拉伯标准、计量和质量组织（SASO）指定机构</span>
          </el-col>
          <el-col :span="12">
            <span class="dian">●</span>
            <span style="font-size: 20px;margin-left: 20px">阿曼空调能效标签注册评定指定机构</span>
          </el-col>
        </el-row>
        <el-row style="margin-bottom: 40px">
          <el-col :span="12">
            <span class="dian">●</span>
            <span style="font-size: 20px;margin-left: 20px">美国环保局能源之星（EPA）认证机构</span>
          </el-col>
          <el-col :span="12">
            <span class="dian">●</span>
            <span style="font-size: 20px;margin-left: 20px">英国建筑研究院（BRE）授权的英国BREEAM绿色建筑国际注册评估师和BREEAM绿色建筑咨询师培训课程国内培训资质</span>
          </el-col>
        </el-row>
        <el-row style="margin-bottom: 40px">
          <el-col :span="12">
            <span class="dian">●</span>
            <span style="font-size: 20px;margin-left: 20px">荷兰交通管理局（RDW）认可的C类技术服务机构，提供欧洲机动车生产一致性工厂检查服务</span>
          </el-col>
          <el-col :span="12">
            <span class="dian">●</span>
            <span style="font-size: 20px;margin-left: 20px"></span>
          </el-col>
        </el-row>
      </div>
      <div style="margin-top: 40px;font-size: 30px;font-weight: bold">
        国内资质
      </div>
      <div style="margin-top: 40px">
        <el-row style="margin-bottom: 40px">
          <el-col :span="12">
            <span class="dian">●</span>
            <span style="font-size: 20px;margin-left: 20px">中国国家认证认可监督管理委员会批准的产品认证机构</span>
          </el-col>
          <el-col :span="12">
            <span class="dian">●</span>
            <span style="font-size: 20px;margin-left: 20px">中国国家认证认可监督管理委员会批准的管理体系认证机构</span>
          </el-col>
        </el-row>
        <el-row style="margin-bottom: 40px">
          <el-col :span="12">
            <span class="dian">●</span>
            <span style="font-size: 20px;margin-left: 20px">中国国家认证认可监督管理委员会批准的认证培训机构</span>
          </el-col>
          <el-col :span="12">
            <span class="dian">●</span>
            <span style="font-size: 20px;margin-left: 20px">中国国家认证认可监督管理委员会指定的强制性产品认证承担机构</span>
          </el-col>
        </el-row>
        <el-row style="margin-bottom: 40px">
          <el-col :span="12">
            <span class="dian">●</span>
            <span style="font-size: 20px;margin-left: 20px">中国合格评定国家认可委员会认可的认证机构</span>
          </el-col>
          <el-col :span="12">
            <span class="dian">●</span>
            <span style="font-size: 20px;margin-left: 20px">中国国家发展和改革委员会授权的“节”字标志产品认证机构</span>
          </el-col>
        </el-row>
        <el-row style="margin-bottom: 40px">
          <el-col :span="12">
            <span class="dian">●</span>
            <span style="font-size: 20px;margin-left: 20px">中国国家发展和改革委员会、国家财政部“十二五”首批第三方节能量审核机构</span>
          </el-col>
          <el-col :span="12">
            <span class="dian">●</span>
            <span style="font-size: 20px;margin-left: 20px">中国国家科技部、财政部、住房和城乡建设部及国家能源局认可的“金太阳示范工程”光伏产品认证机构</span>
          </el-col>
        </el-row>
        <el-row style="margin-bottom: 40px">
          <el-col :span="12">
            <span class="dian">●</span>
            <span style="font-size: 20px;margin-left: 20px">中国国家工业和信息化部、国家密码管理局批准的电子认证服务机构</span>
          </el-col>
          <el-col :span="12">
            <span class="dian">●</span>
            <span style="font-size: 20px;margin-left: 20px">中国国家商务部认可的出口商品技术服务中心</span>
          </el-col>
        </el-row>
        <el-row style="margin-bottom: 40px">
          <el-col :span="12">
            <span class="dian">●</span>
            <span style="font-size: 20px;margin-left: 20px">中国政府“绿色照明工程”认可的认证机构</span>
          </el-col>
          <el-col :span="12">
            <span class="dian">●</span>
            <span style="font-size: 20px;margin-left: 20px">中国北京、天津、上海、重庆、湖北、广东及深圳等地碳排放权交易所合作伙伴</span>
          </el-col>
        </el-row>
      </div>
      <div style="margin-top: 40px;font-size: 30px;font-weight: bold">
       采信
      </div>
      <div style="margin-top: 40px">
        <el-row style="margin-bottom: 40px">
          <el-col :span="24">
            <span class="dian">●</span>
            <span style="font-size: 20px;margin-left: 20px">中国质量认证中心颁发的中国强制性产品认证（CCC）证书是产品进入中国市场的准入条件，也是参与“家电下乡”、“汽车下乡”等惠民工程的必要条件</span>
          </el-col>
        </el-row>
        <el-row style="margin-bottom: 40px">
          <el-col :span="24">
            <span class="dian">●</span>
            <span style="font-size: 20px;margin-left: 20px">中国质量认证中心颁发的CB测试证书与国际电工委员会电工设备及零部件合格评定组织-测试证书互认体系（IECEE-CB）内的53个国家和地区的69个国家认证机构实现互认</span>
          </el-col>
        </el-row>
        <el-row style="margin-bottom: 40px">
          <el-col :span="24">
            <span class="dian">●</span>
            <span style="font-size: 20px;margin-left: 20px">中国质量认证中心颁发的质量管理体系（ISO9001）、环境管理体系（ISO14001）、职业健康安全管理体系（OHSMS18001）证书与IQNet联盟内其他34个国家和地区的38个成员机构实现互认</span>
          </el-col>
        </el-row>
        <el-row style="margin-bottom: 40px">
          <el-col :span="24">
            <span class="dian">●</span>
            <span style="font-size: 20px;margin-left: 20px">中国质量认证中心的危害分析关键控制点（HACCP）认证结果被官方出口食品企业备案工作采信，采信的认证企业可免除官方出口食品企业现场审核</span>
          </el-col>
        </el-row>
        <el-row style="margin-bottom: 40px">
          <el-col :span="24">
            <span class="dian">●</span>
            <span style="font-size: 20px;margin-left: 20px">中国质量认证中心的危害分析关键控制点（HACCP）认证、食品安全管理体系认证及葡萄酒灌装过程可追溯性审核，成为获得澳大利亚葡萄酒局出口中国葡萄酒许可资格的基本条件之一</span>
          </el-col>
        </el-row>
        <el-row style="margin-bottom: 40px">
          <el-col :span="24">
            <span class="dian">●</span>
            <span style="font-size: 20px;margin-left: 20px">良好农业规范（GAP）认证、有机产品认证的企业可享受国家食品、农产品认证扶持政策</span>
          </el-col>
        </el-row>
        <el-row style="margin-bottom: 40px">
          <el-col :span="24">
            <span class="dian">●</span>
            <span style="font-size: 20px;margin-left: 20px">获得GLOBALGAP认证的出口产品，将获得欧洲零售商的采信</span>
          </el-col>
        </el-row>
      </div>
      <div style="margin-top: 40px;font-size: 30px;font-weight: bold">
        中国质量认证中心认证结果
      </div>
      <div style="margin-top: 40px">
        <el-row style="margin-bottom: 40px">
          <el-col :span="24">
            <span class="dian">●</span>
            <span style="font-size: 20px;margin-left: 20px">进入中国《节能产品政府采购清单》的必要条件</span>
          </el-col>
        </el-row>
        <el-row style="margin-bottom: 40px">
          <el-col :span="24">
            <span class="dian">●</span>
            <span style="font-size: 20px;margin-left: 20px">国家“节能产品惠民工程”、“金太阳示范工程”及高效照明财政补贴等政策支持的重要条件</span>
          </el-col>
        </el-row>
        <el-row style="margin-bottom: 40px">
          <el-col :span="24">
            <span class="dian">●</span>
            <span style="font-size: 20px;margin-left: 20px">政府及企事业单位招投标工作的重要依据</span>
          </el-col>
        </el-row>
        <el-row style="margin-bottom: 40px">
          <el-col :span="24">
            <span class="dian">●</span>
            <span style="font-size: 20px;margin-left: 20px">消费者选购商品的重要参考</span>
          </el-col>
        </el-row>
      </div>
    </div>

  </div>
</template>

<script>
  import Banner from './component/banner'
  import Service from './component/service'

  export default {
    name: 'prefecture',
    components: {
      // Banner,
      Service
    },
    data() {
      return {
        bannerPath: require('../../../assets/img/prefecture/cqcBanner.png'),
        serviceList: [],
        cfItemList: [
          {
            itemCode: '50',
            itemName: '全部'
          },
          {
            itemCode: '5001',
            itemName: '体系认证'
          },
          {
            itemCode: '5002',
            itemName: '国家强制性产品认证(CCC认证)'
          },
          {
            itemCode: '5006',
            itemName: '有毒有害物质限量认证'
          },
          {
            itemCode: '5007',
            itemName: '中国绿色产品认证'
          },
          {
            itemCode: '5008',
            itemName: '国推自愿性低碳产品认证'
          },
          {
            itemCode: '5009',
            itemName: '自愿性产品认证'
          },
          {
            itemCode: '5010',
            itemName: '双碳技术服务'
          },
          {
            itemCode: '5011',
            itemName: '农食产品认证'
          }
        ],
        pageIndex: 1,
        pageSize: 10,
        totalCount: 0
      }
    },
    created() {
      this.prefectureKey = this.$route.params.prefectureKey
      this.prefectureName = this.$route.params.prefectureName
      // this.getCfItemList()
      this.getDataList('5006', 1, 6)
    },
    methods: {
      getCfItemList() {
        this.$http({
          url: this.$http.adornUrl(this.$api.PUB.CF_ITEM),
          methods: 'get',
          params: this.$http.adornParams({
            enterpriseId: 'a9f40762402c4b519f4c748a91824b43'
          })
        }).then(({data}) => {
          if (data && data.code === 0) {
            this.cfItemList = data.list
          }
        })
      },

      getDataList(itemCode, pageIndex, pageSize) {
        this.$http({
          url: this.$http.adornUrl(this.$api.PUB.CQC_PRODUCT),
          methods: 'get',
          params: this.$http.adornParams({
            page: pageIndex,
            limit: pageSize,
            itemCode: itemCode || null,
            enterpriseId: 'a9f40762402c4b519f4c748a91824b43'
          })
        }).then(({data}) => {
          if (data && data.code === 0) {
            this.serviceList = data.page.list
            this.pageIndex = pageIndex
            this.pageSize = data.page.pageSize
            this.totalCount = data.page.totalCount
          } else {
            this.dataList = []
            this.totalCount = 0
            this.totalPage = 1
          }
        })
      }
    }
  }
</script>

<style scoped>
  .jianjie {
    background-image: url("../../../assets/img/prefecture/topIntroduction.png");
    background-size: cover;
    height: 850px;
  }

  .zizhi {
    background-image: url("../../../assets/img/prefecture/topBackground.png");
    background-size: cover;
    height: 3400px;
    padding: 80px 80px 40px 80px;
  }

  .dian {
    font-size: 30px;
    color: #ff8d0d;
    text-shadow: 2px 2px black;
  }
  .banner-wrapper {
    width: 100%;
    height: 500px;
    background-image: url("../../../assets/img/prefecture/cqcBanner.png");
    background-size: cover;
  }
</style>
